
/* tslint:disable max-line-length | no-trailing-whitespace */
import * as React from 'react';
import { Types } from '@shapeable/core';

export const ApplicationLogoInverted: React.FC<Types.Classable> = ({ className }) => (
  <svg className={className} version="1.1" x="0px" y="0px" viewBox="0 0 280 80" preserveAspectRatio="none">
<path fill="#FFFFFF" d="M-0.1,52.2V25.8H1v4h0.2c0.9-2.4,3.5-4.6,8.3-4.6c5.9,0,9.1,3.5,9.1,10.1v17h-1.1V35.4
	c0-6.4-2.9-9.3-8.1-9.3C5,26.2,1,28.5,1,33.3v18.9C1,52.2-0.1,52.2-0.1,52.2z"/>
<path fill="#FFFFFF" d="M22.5,39c0-9,4.1-13.8,10.9-13.8c6.4,0,10.7,5.1,10.7,13.2v0.5H23.7v1.4c0,7.1,3.6,11.6,9.9,11.6
	c4.4,0,7.5-2.2,9.1-6.5l0.9,0.4c-1.6,4.3-5,7.1-10.1,7.1C26.6,52.8,22.5,48.1,22.5,39z M23.7,37.7L23.7,37.7l19.2,0.1v-0.2
	c0-6.9-3.8-11.5-9.5-11.5C27.2,26.2,23.7,30.6,23.7,37.7z"/>
<path fill="#FFFFFF" d="M45.3,52.2l9.8-13.3l-9.6-13.1h1.3l8.9,12.3h0.1l8.9-12.3H66l-9.6,13.1l9.8,13.3H65l-9.2-12.5h-0.1
	l-9.2,12.5H45.3z"/>
<path fill="#FFFFFF" d="M73,52.2c-1,0-1.5-0.5-1.5-1.5V26.8h-5v-1h3.8c0.9,0,1.2-0.4,1.2-1.3v-6.2h1.1v7.5h6.3v1h-6.3v24.4h5.9v1
	C78.5,52.2,73,52.2,73,52.2z"/>
<path fill="#FFFFFF" d="M92.1,63.3c-8.4,0-11.9-2.4-11.9-6.6c0-2.9,1.6-4.5,4.3-5.2v-0.6c-1.5-0.7-2.4-1.9-2.4-3.8
	c0-2.7,1.9-4,4.6-4.7v-0.2c-3.2-1.5-5.1-4.5-5.1-8.3c0-5.5,4-9.3,10.1-9.3c1.9,0,3.7,0.4,5.1,1v-0.5c0-2.1,0.9-3.6,3.1-3.6h4v3.7H99
	V27c1.9,1.7,3.1,4.1,3.1,6.9c0,5.5-4,9.3-10.2,9.3c-0.9,0-1.8-0.1-2.6-0.3c-1.8,0.5-3.6,1.4-3.6,3s1.7,2,4.1,2h5.7
	c6.7,0,9.6,2.7,9.6,7.2C105.1,60.7,100.7,63.3,92.1,63.3z M95.6,52h-9c-1.7,0.8-2.5,2-2.5,3.7c0,2.4,1.6,4.3,5.8,4.3h3.9
	c4.6,0,7.4-1.5,7.4-4.4C101.2,53.4,99.8,52,95.6,52z M97.7,34.7v-1.5c0-3.6-2.2-5.2-5.8-5.2c-3.6,0-5.8,1.7-5.8,5.2v1.5
	c0,3.6,2.2,5.2,5.8,5.2C95.4,39.9,97.7,38.2,97.7,34.7z"/>
<path fill="#FFFFFF" d="M104.6,38.7c0-8.6,4.8-14.1,12.1-14.1c7.2,0,11.6,5.5,11.6,13.2v2H109V41c0,4.6,3,8.1,8,8.1
	c3.4,0,6-1.7,7.4-4.6l3,2.1c-1.7,3.7-5.6,6.2-10.7,6.2C109.4,52.8,104.6,47.3,104.6,38.7z M109,36.3v0.4h14.9v-0.6
	c0-4.8-2.9-7.9-7.2-7.9C112.1,28.1,109,31.6,109,36.3z"/>
<path fill="#FFFFFF" d="M132.1,52.2v-27h4.2v4.4h0.2c1.3-3,3.6-5,7.6-5c5.5,0,9,3.8,9,10.3v17.3H149V35.7c0-4.8-2.1-7.3-6.1-7.3
	c-3.3,0-6.6,1.7-6.6,5.1v18.7H132.1z"/>
<path fill="#FFFFFF" d="M158,52.2V24.9h6.7v4.5h0.3c1-2.9,3.2-5.2,7.3-5.2c3.7,0,6.6,1.8,7.9,5.5h0.1c1-3,4-5.5,8.4-5.5
	c5.3,0,8.4,3.9,8.4,10.7v17.2h-6.7V35.7c0-3.9-1.4-5.8-4.4-5.8c-2.6,0-5.1,1.5-5.1,4.4v18h-6.7V35.7c0-3.9-1.4-5.8-4.4-5.8
	c-2.6,0-5.1,1.5-5.1,4.4v18H158V52.2z"/>
<path fill="#FFFFFF" d="M199.5,38.5c0-8.6,4.6-14.2,12.6-14.2c8.5,0,12.4,6.3,12.4,13.7v2.2h-18.1v0.7c0,3.9,2.2,6.6,6.6,6.6
	c3.3,0,5.3-1.6,6.9-3.7l3.6,4c-2.2,3.1-6.2,5-11.3,5C204.3,52.8,199.5,47.3,199.5,38.5z M206.4,35.7v0.4h11.2v-0.5
	c0-3.8-1.9-6.3-5.4-6.3C208.7,29.3,206.4,31.9,206.4,35.7z"/>
<path fill="#FFFFFF" d="M225.4,44.8c0-5.6,4.2-8.4,11.6-8.4h4.6v-2c0-3-1.5-4.8-5.1-4.8c-3.1,0-4.9,1.6-6.2,3.4l-4-3.6
	c2-3.1,5.2-5.2,10.7-5.2c7.4,0,11.3,3.5,11.3,9.7v12.8h2.7V52h-3.7c-2.9,0-4.6-1.9-5.1-4.7H242c-0.9,3.5-3.9,5.3-7.9,5.3
	C228.5,52.8,225.4,49.7,225.4,44.8z M241.7,44.1v-3.6h-4.3c-3.5,0-5.2,1.2-5.2,3.3v0.9c0,2.2,1.5,3.3,4.1,3.3
	C239.3,48,241.7,46.6,241.7,44.1z"/>
<path fill="#FFFFFF" d="M261.7,52.2c-4.6,0-7.1-2.5-7.1-6.9v-15h-4V25h2.1c2,0,2.6-0.9,2.6-2.8v-4.6h6v7.5h5.6v5.3h-5.6v16.7h5.2
	v5.3h-4.8V52.2z"/>
<path fill="#FFFFFF" d="M270.1,21.7c0-2.9,2.2-5,4.9-5c2.7,0,4.9,2.2,4.9,5s-2.2,5-4.9,5C272.3,26.7,270.1,24.6,270.1,21.7z
	 M278.7,22.1v-0.7c0-2.1-1.6-3.6-3.7-3.6s-3.7,1.4-3.7,3.6v0.7c0,2.1,1.6,3.6,3.7,3.6S278.7,24.2,278.7,22.1z M274.4,24.3H273v-5.1
	h2.4c1.2,0,1.8,0.7,1.8,1.7c0,0.8-0.4,1.3-0.9,1.6l1.1,1.9h-1.5l-0.9-1.6h-0.7v1.5H274.4z M275.1,21.6c0.4,0,0.7-0.2,0.7-0.5v-0.3
	c0-0.4-0.2-0.5-0.7-0.5h-0.8v1.4h0.8V21.6z"/>
	
  </svg>
);

