/* tslint:disable max-line-length | no-trailing-whitespace */
import * as React from 'react';
import { Types } from '@shapeable/core';

export const CreditLogo: React.FC<Types.Classable> = ({ className }) => (
  <svg className={className} version="1.1" x="0px" y="0px" viewBox="0 0 250.358 44.973" preserveAspectRatio="none">
    <g id="Group_2759" data-name="Group 2759" transform="translate(-694.855 124.996)">
    <g id="Group_2756" data-name="Group 2756">
      <path id="Path_3505" data-name="Path 3505" d="M804.422-93.305l-6.372-11.871a3.7,3.7,0,0,0-.824,1.591L795.4-95.78a3.714,3.714,0,0,0,1.809,4.084l12.954,8a3.816,3.816,0,0,0,5.271-1.572l.244-.482-9.866-6.081A3.909,3.909,0,0,1,804.422-93.305Z" fill="#fff" opacity="0.5"/>
      <path id="Path_3506" data-name="Path 3506" d="M838.57-110.279a4.247,4.247,0,0,0,.181-6.665l-8.655-7.128a4.013,4.013,0,0,0-3.736-.747l-9.617,2.957a4.059,4.059,0,0,0-.859.394l21.042,12.4Z" fill="#fff" opacity="0.5"/>
      <path id="Path_3507" data-name="Path 3507" d="M808.525-106.469v-5.305h5.3l.005-6.082a4.2,4.2,0,0,1,2.053-3.612L810.8-124.46a3.86,3.86,0,0,0-4.715.62l-9.654,9.789a3.949,3.949,0,0,0-.663,4.634l2.277,4.241a3.8,3.8,0,0,1,2.871-1.3Z" fill="#fff" opacity="0.5"/>
      <path id="Path_3508" data-name="Path 3508" d="M813.824-109.13H811.17v2.664l2.654,0v-2.667Z" fill="#fff" opacity="0.5"/>
      <path id="Path_3509" data-name="Path 3509" d="M838.349-108.233l-1.423-.839-9.891,7.259v8.548h-7.542l-3.813,7.52,8.35,5.146a3.87,3.87,0,0,0,5.5-1.6l10.333-20.9A3.939,3.939,0,0,0,838.349-108.233Z" fill="#fff" opacity="0.5"/>
      <path id="Path_3510" data-name="Path 3510" d="M824.391-99.872l-.747.548a4.032,4.032,0,0,1-1.887.744,3.692,3.692,0,0,1-.4,1.634l-.526,1.037h3.558Z" fill="#fff" opacity="0.5"/>
      <path id="Path_3511" data-name="Path 3511" d="M813.814-93.265v-2.644h7.019l.526-1.037a3.692,3.692,0,0,0,.4-1.634,4.076,4.076,0,0,1-.494.039h-3.347a4.137,4.137,0,0,1-4.087-4.18l-.005-3.742-2.654,0v7.913h-2.645v-7.916l-7.6-.008a3.8,3.8,0,0,0-2.871,1.3l6.372,11.871a3.909,3.909,0,0,0,1.392,1.479l9.866,6.081,3.813-7.52Z" fill="#fff" opacity="0.75"/>
      <path id="Path_3512" data-name="Path 3512" d="M813.831-117.856l-.005,6.082h7.92v2.644h-7.922l0,1.648,0,1.019,4.15,0a3.761,3.761,0,0,1,3.785,3.734v4.108c0,.012,0,.024,0,.036a4.032,4.032,0,0,0,1.887-.744l.747-.548v-6.614h2.644v4.673l9.891-7.259-21.042-12.4A4.2,4.2,0,0,0,813.831-117.856Z" fill="#fff" opacity="0.75"/>
      <path id="Path_3513" data-name="Path 3513" d="M821.759-102.724a3.761,3.761,0,0,0-3.785-3.734l-4.15,0,.005,3.742a4.137,4.137,0,0,0,4.087,4.18h3.347a4.076,4.076,0,0,0,.494-.039c0-.012,0-.024,0-.036Z" fill="#fff"/>
    </g>
    <g id="Group_2757" data-name="Group 2757">
      <path id="Path_3514" data-name="Path 3514" d="M846.645-99.824c.137,1.517,1.287,2.069,2.689,2.069.988,0,2.711-.207,2.643-1.563-.069-1.379-1.977-1.54-3.885-1.976-1.93-.414-3.815-1.1-3.815-3.517,0-2.6,2.8-3.4,4.965-3.4,2.436,0,4.642,1.011,4.964,3.654h-2.735c-.23-1.241-1.264-1.586-2.413-1.586-.759,0-2.161.184-2.161,1.218,0,1.287,1.931,1.471,3.861,1.908,1.908.437,3.839,1.126,3.839,3.471,0,2.826-2.85,3.861-5.31,3.861-2.987,0-5.216-1.333-5.263-4.138Z" fill="#fff"/>
      <path id="Path_3515" data-name="Path 3515" d="M855.675-111.774h2.62v5.447h.046a4.158,4.158,0,0,1,3.563-1.885c2.574,0,4.205,1.379,4.205,4.045v8.159h-2.62v-7.47c-.045-1.861-.781-2.666-2.321-2.666a2.9,2.9,0,0,0-2.873,3.126v7.01h-2.62Z" fill="#fff"/>
      <path id="Path_3516" data-name="Path 3516" d="M877.9-98.651c0,.643.138.9.621.9a3.62,3.62,0,0,0,.643-.046v1.816a6.575,6.575,0,0,1-1.655.3c-1.057,0-1.815-.368-2.022-1.448a6.1,6.1,0,0,1-4.114,1.448c-2.138,0-4.068-1.15-4.068-3.471,0-2.965,2.367-3.447,4.574-3.7,1.884-.345,3.562-.138,3.562-1.678,0-1.356-1.4-1.609-2.459-1.609-1.471,0-2.505.6-2.62,1.885h-2.62c.183-3.057,2.78-3.953,5.4-3.953,2.322,0,4.758.942,4.758,3.447Zm-2.62-3.31c-.8.529-2.069.506-3.218.713-1.126.184-2.137.6-2.137,1.976,0,1.172,1.494,1.517,2.413,1.517,1.149,0,2.942-.6,2.942-2.252Z" fill="#fff"/>
      <path id="Path_3517" data-name="Path 3517" d="M879.873-107.89h2.482v1.608h.046a3.97,3.97,0,0,1,3.677-1.93c3.747,0,5.562,2.85,5.562,6.32,0,3.195-1.609,6.206-5.125,6.206a4.621,4.621,0,0,1-3.976-1.862h-.046v5.93h-2.62Zm5.861,1.746c-2.207,0-3.333,1.678-3.333,4.206,0,2.391,1.218,4.183,3.333,4.183,2.39,0,3.286-2.114,3.286-4.183C889.02-104.1,887.894-106.144,885.734-106.144Z" fill="#fff"/>
      <path id="Path_3518" data-name="Path 3518" d="M895.073-101.271c0,1.861,1.011,3.516,3.194,3.516a2.755,2.755,0,0,0,2.9-1.976h2.482a5.2,5.2,0,0,1-5.378,4.045c-3.723,0-5.814-2.6-5.814-6.252,0-3.378,2.206-6.274,5.745-6.274,3.747,0,6.045,3.378,5.608,6.941ZM901.186-103a3.107,3.107,0,0,0-2.988-3.149A3.105,3.105,0,0,0,895.073-103Z" fill="#fff"/>
      <path id="Path_3519" data-name="Path 3519" d="M915.211-98.651c0,.643.138.9.621.9a3.608,3.608,0,0,0,.643-.046v1.816a6.566,6.566,0,0,1-1.654.3c-1.058,0-1.816-.368-2.023-1.448a6.1,6.1,0,0,1-4.114,1.448c-2.137,0-4.068-1.15-4.068-3.471,0-2.965,2.367-3.447,4.574-3.7,1.884-.345,3.562-.138,3.562-1.678,0-1.356-1.4-1.609-2.459-1.609-1.471,0-2.505.6-2.62,1.885h-2.62c.184-3.057,2.781-3.953,5.4-3.953,2.321,0,4.757.942,4.757,3.447Zm-2.62-3.31c-.8.529-2.068.506-3.217.713-1.127.184-2.138.6-2.138,1.976,0,1.172,1.494,1.517,2.413,1.517,1.149,0,2.942-.6,2.942-2.252Z" fill="#fff"/>
      <path id="Path_3520" data-name="Path 3520" d="M917.186-111.774h2.62v5.424h.046a4.327,4.327,0,0,1,3.54-1.862c3.746,0,5.562,2.85,5.562,6.32,0,3.195-1.609,6.206-5.126,6.206-1.609,0-3.332-.391-4.114-1.954h-.046v1.632h-2.482Zm5.861,5.63c-2.206,0-3.333,1.678-3.333,4.206,0,2.391,1.218,4.183,3.333,4.183,2.39,0,3.286-2.114,3.286-4.183C926.333-104.1,925.207-106.144,923.047-106.144Z" fill="#fff"/>
      <path id="Path_3521" data-name="Path 3521" d="M930.091-111.774h2.62v15.766h-2.62Z" fill="#fff"/>
      <path id="Path_3522" data-name="Path 3522" d="M936.426-101.271c0,1.861,1.011,3.516,3.195,3.516a2.756,2.756,0,0,0,2.9-1.976H945a5.2,5.2,0,0,1-5.378,4.045c-3.723,0-5.815-2.6-5.815-6.252,0-3.378,2.207-6.274,5.746-6.274,3.746,0,6.044,3.378,5.608,6.941ZM942.54-103a3.108,3.108,0,0,0-2.988-3.149A3.105,3.105,0,0,0,936.426-103Z" fill="#fff"/>
    </g>
    <text id="powered_by" data-name="powered by" transform="translate(694.855 -96.111)" fill="#fff" fontSize="19" fontFamily="Helvetica"><tspan x="0" y="0">powered by</tspan></text>
  </g>
  </svg>
);
